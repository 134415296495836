import { createMuiTheme } from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints"

const breakpoints = createBreakpoints({})

const AppTheme = createMuiTheme({
    typography: {
        fontFamily: "Barlow",
        fontSize: 16,
        [breakpoints.down("sm")]: {
            fontSize: 32,
            color: "red"
        },
        h4: {
            fontFamily: "Century Gothic",
            fontWeight: 700,
            textTransform: "uppercase",
            marginBottom: 25,
            [breakpoints.up("xs")]: {
                margin: "10px 0",
            }
        },
    },
    palette: {
        text: {
            primary: "#00205B",
            secondary: "#00205B",
        }
    }
});

export default AppTheme;