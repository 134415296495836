import { Box } from '@material-ui/core';
import React from 'react';
import IDCOCArd from './IDCOCard';


function App() {
    return (
        <div className="App">
            <Box
                display="flex"
                mx="auto"
                maxWidth={1024}
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
                flexDirection="column"
                fontFamily="Barlow, Arial, sans-serif' !important"
                margin="0 auto"
            >
                <IDCOCArd></IDCOCArd>
            </Box>
        </div>
    );
}

export default App;
